import { WFMBVehiclesResponse, WFMBVehiclesBudgetResponse, WFMBVehiclesOfferResponse } from "../webservices/models/wfmb.models";

export class VehiclesResponse {
  // from api
  almostInBudget = [] as  VehiclesResponseItem[];
  inBudget = [] as VehiclesResponseItem[];
  series = '';

  // vm properties
  seriesName = '';
  image = '';
  summary = '';
  summaryBullets = [] as string[];

  constructor(response: WFMBVehiclesResponse) {
    this.series = response.series;
    this.inBudget = response.inBudget.map(item => new VehiclesResponseItem(item))
    this.almostInBudget= response.almostInBudget.map(item => new VehiclesResponseItem(item))
  }
}

export class VehiclesResponseItem {
  // from api
  model = ''
  modelDesc = ''
  offer = undefined as VehiclesResponseOffer | undefined
  offers = [] as VehiclesResponseOffer[];
  payment = 0 
  purchaseType = ''
  year = ''
  tier = ''
  term =  0
  downPayment = 0
  rate =  0
  dueAtSigning = 0
  jelly = ''

  // vm properties
  series = ''
  seriesName = ''
  category = ''
  grade = ''
  gradeDescription = ''
  almost = false
  almostPayment = 0 
  carJellyImage = ''
  underLimit = false
  budgetDifference = 0 
  selected = false

  constructor(response?: WFMBVehiclesBudgetResponse) {
    if (response) {
      this.model = response.model;
      this.dueAtSigning = response.dueAtSigning;
      this.downPayment = response.downPayment;
      this.modelDesc = response.modelDesc;
      this.payment = response.payment;
      this.purchaseType = response.purchaseType;
      this.rate = response.rate;
      this.term = response.term;
      this.tier = response.tier;
      this.year = response.year;
      this.offers = response.offers.map(item => new VehiclesResponseOffer(item))
      this.jelly = response.jelly;

      // find advertised offer. RATE_INCENTIVE is highest priority
      this.offer = this.offers.reduce((retOffer: VehiclesResponseOffer | undefined, item) => {
          if (retOffer && retOffer.type === 'RATE_INCENTIVE') {
            return retOffer;
          }
          if (item.advertised) {
            return item
          }
          return retOffer;
        },
        undefined
      );
    }
  }
}

export class VehiclesResponseOffer {
  endDate = ''
  advertisedVinMsrp = null as number | null;
  advertisedTrim = ''
  subCash = null as number | null;
  type = ''
  isSubvented = false;
  offerType = '';
  tier = ''
  rate = null as number | null;
  advertised = false;
  modelCode = '';
  offerId = '';
  term = null as number | null;
  grossCapCost = null as number | null;
  compatibility = [] as string[];
  startDate = ''
  mileage = null as number | null;

  constructor(response: WFMBVehiclesOfferResponse) {
    Object.assign(this, response);
  }

}
