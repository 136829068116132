import { API } from './api';
import { VapiRequest } from './models/vapi.models';

export const getSeries = ({ lang }: VapiRequest) => {
  const efcSeriesUrl = `${process.env.REACT_APP_VAPI_BASE_URL}/availableSeries/toyota/NATIONAL/${lang}`;
  return API({
    method: 'GET',
    url: efcSeriesUrl,
    headers: { 'x-api-key': process.env.REACT_APP_VAPI_API_KEY },
  });
};

export const getTrims = ({ lang }: VapiRequest) => {
  const efcTrimsUrl = `${process.env.REACT_APP_VAPI_BASE_URL}/trims/toyota/NATIONAL/${lang}`;
  return API({
    method: 'GET',
    url: efcTrimsUrl,
    headers: { 'x-api-key': process.env.REACT_APP_VAPI_API_KEY },
  });
};
