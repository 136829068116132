import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'mobx-react';
import Script from 'react-load-script';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import './i18n';
import AppStore, { AppStoreContext } from './stores/AppStore';
import { getMinimumPayment } from './stores/xforms';
import VehicleDetailsController from './modules/VehicleDetails/VehicleDetailsController';
import BudgetInterstitial from './modules/BudgetInterstitial';
import ErrorPanel from './modules/ErrorPanel';
import useMobileDetect from '../src/hooks/useMobileDetect';

library.add(fas);

const stores = {
  AppStore
}

declare global {
  interface Window {
    fireTag: (event: string, data: string) => void
  }
}

const App = () => {

  const { AppStore: { getVehicles, getZipInfo, getEfcData, setFilters, setDomain }} = useContext(AppStoreContext);
  const analyticsScript = process.env.REACT_APP_ANALYTICS_SCRIPT || '';
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ isScriptsLoaded, setIsScriptsLoaded ] = useState(process.env.REACT_APP_ANALYTICS_DISABLED !== undefined);
  const [ error, setIsError ] = useState(false);
  const { isMobile } = useMobileDetect();

  useEffect(() => {

    ( async () => {
      setDomain();
      // Retrieve zip from url if exists
      const params = window.location.search
        .substring(1, window.location.search.length)
        .split('&')
        .reduce((map, param) => {
          const p = param.split('=');
          return {
            ...map,
            [p[0]]: p[1]
          };
        }, {zip: '', xact: ''});
        setFilters(isMobile);
        
      try {
        await getZipInfo(params.zip);
        const vehicleResponse = await getVehicles();
        const minimumPayment = getMinimumPayment(vehicleResponse);
        AppStore.min = AppStore.currentBudgetValueMin = minimumPayment;
        AppStore.xact = params.xact;
        setIsLoaded(true);
      } catch (e) {
        setIsLoaded(true);
        setIsError(true);
      }

    })();
  }, [setDomain, setFilters, isMobile, getZipInfo, getEfcData, getVehicles]);

  const handleScriptCallback = () => {
    setIsScriptsLoaded(true)
  };

  return (
    <Provider {...stores}>
      { !process.env.REACT_APP_ANALYTICS_DISABLED &&
        <Script
          url={analyticsScript}
          onLoad={() => handleScriptCallback()}
          onError={() => handleScriptCallback()}
        /> 
      }
      { (isLoaded && isScriptsLoaded) &&
        <>
          { error ?
            <Router>
              <Route path="/" exact component={ErrorPanel} />
            </Router>
            :
            <Router>
              <Route path="/" exact component={BudgetInterstitial} />
              <Route path="/vehicleDetails/:series/:model/:financeType" component={VehicleDetailsController} />
              <Route path="/error" component={ErrorPanel} />
            </Router>
          }
        </>
      }
    </Provider>
  );
};

export default App;
