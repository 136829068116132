import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';

import { AppStoreContext } from '../../stores/AppStore';
import { PostMessageStoreContext } from '../../stores/PostMessageStore';
import { VehiclesResponseItem } from '../../types/vehiclesResponse.models';
import { MarketingOffer } from '../../types/marketingOffer.models';
import { getBudgetArrays } from '../../utils/budgetUtils';
import { fireTag } from '../../utils/Analytics';
import getGlobalBlock from '../../utils/Analytics/blocks/getGlobalBlock';
import getPageBlock from '../../utils/Analytics/blocks/getPageBlock';
import { getOfferBlockFromResponse } from '../../utils/Analytics/blocks/getOfferBlock';
import { getVehicleBlockFromResponse } from '../../utils/Analytics/blocks/getVehicleBlock';
import Header from '../../components/Header';
import getLinkBlock from '../../utils/Analytics/blocks/getLinkBlock';
import FadeIn from '../../components/FadeIn';
import CarBanner from '../../components/CarBanner';
import Container from '../../components/Container';
import FinanceInfo from '../../components/FinanceInfo';
import Loading from '../../components/Loading';
import VehicleDetailsAccordion from './VehicleDetailsAccordion';
import styles from './VehicleDetails.module.scss';
import ErrorPanel from '../ErrorPanel';
import toyotaCareImg from '../../images/ToyotaCare-VIS_150x150.jpg';
import useMobileDetect from '../../../src/hooks/useMobileDetect';

const VehicleDetails = () => {

  const { t } = useTranslation();
  const tBackTo = t('vehicleDetails.backTo');

  const {
    AppStore: {
      selectedSeries,
      selectedTrim,
      vehiclesList,
      getMarketingData,
      domain,
      assignCampaignCode,
      zip,
      language,
      filters,
      seriesData
    }
  } = useContext(AppStoreContext);

  const {
    postMessageStore: { sendHeight, scrollTop }
  } = React.useContext(PostMessageStoreContext);

  const contentSection = 'wfmb_offer_overview';

  const [ inBudgetOffers, setInBudgetOffers ] = useState([] as VehiclesResponseItem[]);
  const [ almostInBudgetOffers, setAlmostInBudgetOffers ] = useState([] as VehiclesResponseItem[]);
  const [ isLoaded , setIsLoaded ] = useState(false);
  const [ headerTitle, setHeaderTitle ] = useState('');
  const [ carJelly, setCarJelly ] = useState('');
  const [ error, setError ] = useState(false);
  const [ marketingOffers, setMarketingOffers ] = useState([] as MarketingOffer[]);
  const [ redirect, setRedirect ] = useState(false);
  const { isMobile } = useMobileDetect();

  React.useEffect(() => {
    switch (domain) {
      case 'buyatoyota' : {
        assignCampaignCode(isMobile ? 
          `${process.env.REACT_APP_SLP_BAT_CAMPAIGN_CODE_MOBILE_DETAILS}` : 
          `${process.env.REACT_APP_SLP_BAT_CAMPAIGN_CODE_WEBSITE_DETAILS}`);
        break;
      }
      case 'toyota' : {
        assignCampaignCode(isMobile ?
          `${process.env.REACT_APP_SLP_TCOM_CAMPAIGN_CODE_MOBILE_DETAILS}` :
          `${process.env.REACT_APP_SLP_TCOM_CAMPAIGN_CODE_WEBSITE_DETAILS}`);
        break;
      }
    }
  }, [domain, isMobile, assignCampaignCode]);

  React.useEffect(() => {
    ( async () => {
      try {
        if (selectedTrim.series) {
          const { offers, seriesName, jelly } = selectedTrim;
          const marketingPromises = offers.filter(offer => offer.advertised)  
                                          .map(offer => getMarketingData(offer.offerId));
          const marketingResponses = await Promise.all(marketingPromises);
          const marketingOfferBundles = [] as MarketingOffer[];
          marketingResponses.forEach(response => {
            const url = new URL(response.config.url ?? '');
            const offerId = url.searchParams.get('offerId');

            response.data.forEach(item => {
              item.offerBundle.offers.forEach(offer => {
                if (offer.offerId === offerId && !marketingOfferBundles.find(offer => offer.offerId === offerId)) marketingOfferBundles.push(offer);
              });
            });
          });
          setMarketingOffers(marketingOfferBundles);
          
          const carJelly = `${jelly}?size=450,450`;
          setCarJelly(carJelly);

          const { inBudgetOffers, almostInBudgetOffers } = getBudgetArrays(vehiclesList, seriesData);
  
          setInBudgetOffers(inBudgetOffers);
          setAlmostInBudgetOffers(almostInBudgetOffers);
  
          setHeaderTitle(seriesName.toUpperCase() + t('header.detail'));
  
          fireTag(
            'ready',
            {
              metrics: 'km-offers-detail,aa-pageload',
              ...getGlobalBlock(),
              ...getPageBlock(contentSection),
              ...getOfferBlockFromResponse(selectedTrim),
              ...getVehicleBlockFromResponse(selectedTrim),
            }
          );
  
          setIsLoaded(true);
          scrollTop();
          sendHeight();
        }
      } catch (e) {
        setError(true);
        setIsLoaded(true);
      }
    })();
  }, [getMarketingData, vehiclesList, selectedTrim, selectedSeries, setInBudgetOffers, scrollTop, sendHeight, t, seriesData]);

  if (redirect) {
    return <Redirect to={`/?zip=${zip}/?lang=${language}/?filters=${filters}`} />
  }

  if (!isLoaded) {
    return <Loading />
  }

  return (
    error ? <ErrorPanel /> :
    <>
      <Header
        title={headerTitle}
        onBack={() => {
          fireTag(
            'aa-link',
            {
              ...getGlobalBlock(),
              ...getPageBlock(contentSection),
              ...getLinkBlock({
                url: '/',
                href: '/',
                typeTitle: 'Back',
                trigger: 'aa-link',
                buttonAction: 'Back',
                type: 'Button'
              }),
              ...getOfferBlockFromResponse(selectedTrim),
              ...getVehicleBlockFromResponse(selectedTrim), 
            }
          );
        }}
      />
      <div className={styles.vehicleDetailsBackground}>
        <FadeIn>
          <CarBanner carImage={carJelly} /> 
          <Container>
            <section className={styles.subHeader}>
              <button className={styles.subHeaderBack} onClick={() => {
                fireTag('aa-link', {
                  ...getGlobalBlock(),
                  ...getPageBlock(contentSection),
                  ...getLinkBlock({
                    url: '/',
                    href: '/',
                    text: tBackTo,
                    typeTitle: tBackTo,
                    trigger: 'aa-link',
                    type: 'Button',
                  }),
                  ...getOfferBlockFromResponse(selectedTrim),
                  ...getVehicleBlockFromResponse(selectedTrim), 
                });
                setRedirect(true);
              }}>
                <span className={styles.subHeaderBackText}>
                  <span>{tBackTo}</span>
                  <span>{inBudgetOffers.length + almostInBudgetOffers.length} {t('vehicleDetails.vehicles')}</span>
                </span>
              </button>
              <div className={styles.subHeaderToyCare}>
                <img src={toyotaCareImg} alt="Toyota Care" />
              </div>
            </section>
          </Container>

          <FinanceInfo
            selectedTrim={selectedTrim}
            onRaqOpen={(text) => {
              fireTag('aa-link', {
                ...getGlobalBlock(),
                ...getPageBlock(contentSection),
                ...getLinkBlock({
                  text,
                  typeTitle: text,
                  trigger: 'aa-link',
                  buttonAction: 'Open Modal',
                  type: 'Button'
                }),
                ...getOfferBlockFromResponse(selectedTrim),
                ...getVehicleBlockFromResponse(selectedTrim),
              });
            }}   
          /> 
          
          <Container>
            { selectedTrim.offer &&
              <section className={styles.vehicleDetailsAccordion}>
                <h3 className={styles.accordionTitle}>{t('vehicleDetails.paymentIncludes')}</h3>
                <VehicleDetailsAccordion
                  selectedTrim={selectedTrim}
                  marketingOffers={marketingOffers}
                />
              </section>
            }
          </Container>
        </FadeIn>
      </div>
    </>
  );
};

export default observer(VehicleDetails);
