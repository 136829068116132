import { RefactoredSeriesResponse, VapiAllAvailableSeriesResponse } from 'types/vapiMs.models';

export const refactorSeries = (data: VapiAllAvailableSeriesResponse, years: number[]) => {
  const seriesData: RefactoredSeriesResponse = { series_data: [] };

  data.seriesList.forEach(series => {
    series.years.forEach(seriesYear => {
      if (years.includes(seriesYear.year)) {
        const yearSeries = seriesData.series_data.find(ser => seriesYear.year.toString() === ser.year);
        const seriesObject = {
          code: series.seriesId,
          title: series.seriesName,
        };

        if (!yearSeries?.year) {
          seriesData.series_data.push({
            year: seriesYear.year.toString(),
            series_list: [seriesObject],
          });
        } else {
          yearSeries.series_list.push(seriesObject);
        }
      }
    });
  });

  return seriesData;
};
