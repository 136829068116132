import React from 'react';

import blankCar from '../../images/blank_car_PE.png'
import styles from './CarBanner.module.scss';

interface CarBannerProps {
  carImage: string;
}

const CarBanner = (props: CarBannerProps) => {

  const { carImage } = props;

  return (
    <section className={styles.carBanner}>
      <img src={carImage} alt={carImage} className={styles.carJellyImage} onError={event => { event.currentTarget.src = blankCar}}/>
    </section>
  );
};

export default CarBanner;
