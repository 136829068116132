import { RefactoredSeriesResponse } from 'types/vapiMs.models';
import { VehiclesResponse, VehiclesResponseItem } from '../types/vehiclesResponse.models';
import { isSpecialSeriesMatch } from './isSpecialSeriesMatch';

const getSeriesNameByYear = (seriesData: RefactoredSeriesResponse | undefined, vehicle: VehiclesResponseItem) => {
  if (!vehicle) {
    return;
  }

  const seriesListByYear = seriesData?.series_data.find((year: any) => year.year === vehicle.year);
  const titleForSeries = seriesListByYear?.series_list.find((series: any) => series.code === vehicle.series || isSpecialSeriesMatch(series.code, vehicle.series))?.title;
  if (titleForSeries) {
    vehicle.seriesName = titleForSeries;
  }
};

const getBudgetArrays = (vehiclesList: VehiclesResponse[], seriesData?: RefactoredSeriesResponse) => {
  let inBudgetOffers = [] as VehiclesResponseItem[];
  let almostInBudgetOffers = [] as VehiclesResponseItem[];
  vehiclesList.forEach(vehicle => {
    if (vehicle.inBudget.length) {
      vehicle.inBudget.sort((a, b) => a.payment - b.payment);

      const vehicleWithLowestPayment = vehicle.inBudget[0];

      getSeriesNameByYear(seriesData, vehicleWithLowestPayment);

      inBudgetOffers = [...inBudgetOffers, vehicleWithLowestPayment];
    }
    if (!vehicle.inBudget.length && vehicle.almostInBudget.length) {
      vehicle.almostInBudget.sort((a, b) => a.payment - b.payment);

      const vehicleWithLowestPayment = vehicle.almostInBudget.filter(item => item.underLimit)[0];

      getSeriesNameByYear(seriesData, vehicleWithLowestPayment);
      almostInBudgetOffers = [...almostInBudgetOffers, vehicleWithLowestPayment];
    }
  });

  return {
    inBudgetOffers,
    almostInBudgetOffers,
  };
};

export { getBudgetArrays };
