import { RefactoredTrimsResponse } from 'types/vapiMs.models';
import { WFMBVehiclesResponse } from 'webservices/models/wfmb.models';
import { isSpecialSeriesMatch } from './isSpecialSeriesMatch';

// show only current, previous and next year (efcData contains only current, previous and next year, while vehicle array contains other years)
// filer out (previous - x) years
export const doesVehiclesYearMatches = (vehicle: WFMBVehiclesResponse, efcData: RefactoredTrimsResponse) => {
  for (const yearDetails of efcData.years_list) {
    for (const series of yearDetails.series_list) {
      if (series.series === vehicle.series || isSpecialSeriesMatch(series.series, vehicle.series)) {
        return true;
      }
    }
  }

  return false;
};
