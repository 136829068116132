import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router';
import VehicleDetails from '.';
import { AppStoreContext } from '../../stores/AppStore';
import { findVehicleAndTrim } from '../../utils/vehicleDetailsUtils';
import ErrorPanel from '../ErrorPanel';
import Loading from '../../components/Loading';

const VehicleDetailsController = () => {
  const {
    AppStore: { setSelectedSeries, selectedTrim, setSelectedTrim, vehiclesList, getVehicles },
  } = useContext(AppStoreContext);

  const { series, model, financeType } = useParams<any>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (!selectedTrim.series) {
          const vehicles = await getVehicles();
          const { vehicleFound, selectedSeries, selectedTrim } = findVehicleAndTrim(vehicles, series, model, financeType);

          if (vehicleFound) {
            setSelectedSeries(selectedSeries);
            setSelectedTrim(selectedTrim);
          }
        }
        setIsLoaded(true);
      } catch (e) {
        setError(true);
        setIsLoaded(true);
      }
    })();
  }, [vehiclesList, setSelectedSeries, setSelectedTrim, model, series, financeType, getVehicles, selectedTrim]);

  if (!isLoaded) {
    return <Loading />;
  }

  return error ? <ErrorPanel /> : <VehicleDetails />;
};

export default VehicleDetailsController;
