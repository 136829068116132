import React, { useContext, useState, useEffect } from 'react';
import styles from './BudgetDesktop.module.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { FadeLoader } from 'react-spinners';

import Button from '../../components/Button';
import FadeIn from '../../components/FadeIn';
import vehicleCategories from '../../components/VehicleCategory/vehicleCategories.json';

import { AppStoreContext } from '../../../src/stores/AppStore';
import { PostMessageStoreContext } from '../../stores/PostMessageStore';
import { VehiclesResponseItem } from '../../../src/types/vehiclesResponse.models';
import useChecklist from '../../../src/hooks/useChecklist';
import useMobileDetect from '../../../src/hooks/useMobileDetect';

import { fireTag } from '../../../src/utils/Analytics';
import getGlobalBlock from '../../utils/Analytics/blocks/getGlobalBlock';
import getPageBlock from '../../utils/Analytics/blocks/getPageBlock';
import getLinkBlock from '../../utils/Analytics/blocks/getLinkBlock';
import getOfferListBlock from '../../utils/Analytics/blocks/getOfferListBlock';
import { getBudgetArrays } from '../../../src/utils/budgetUtils';
import ErrorPanel from '../ErrorPanel';

import VehicleResults from './VehicleResults';
import DesktopWrapper from './DesktopWrapper';
import { betweenEq } from '../../utils';
import Slider from './Slider';
import AdditionalOptions from './AdditionalOptions';
import Header from './Header';

let _timeout = undefined as any;

const BudgetDesktop = observer(() => {

  const { t } = useTranslation();
  const { AppStore: {
    currentBudgetValueMin,
    currentBudgetValueMax,
    setBudgetValueMin,
    setBudgetValueMax,
    getVehicles,
    vehiclesList,
    seriesData,
    setCategories,
    setFinanceType,
    showOffersDesktop,
    setShowOffersDesktop,
    min, 
    max,
    domain,
    assignCampaignCode,
    setFilters
  }} = useContext(AppStoreContext);

  const {
    postMessageStore: { sendHeight }
  } = React.useContext(PostMessageStoreContext); 

  const { checklist, setChecklist } = useChecklist([]);
  const [ inBudgetOffers , setInBudgetOffers ] = useState([] as VehiclesResponseItem[]);
  const [ almostInBudgetOffers , setAlmostInBudgetOffers ] = useState([] as VehiclesResponseItem[]);
  const [ isPageLoaded, setIsPageLoaded ] = useState(false);
  const [ areaControl, setAreaControl ] = useState('');
  const [ error, setError ] = useState(false);
  const { isMobile } = useMobileDetect();

  const contentSection = 'wfmb_filter_menu';

  useEffect(() => {
    switch (domain) {
      case 'buyatoyota' : { 
        assignCampaignCode(`${process.env.REACT_APP_SLP_BAT_CAMPAIGN_CODE_WEBSITE_LANDING}`); 
        break; 
      }
      case 'toyota' : { 
        assignCampaignCode(`${process.env.REACT_APP_SLP_TCOM_CAMPAIGN_CODE_WEBSITE_LANDING}`); 
        break; 
      }
    }
  }, [domain, assignCampaignCode]);

  // Make all budget option filters to true
  useEffect(() => {
    setFilters(isMobile);
    setCategories([...vehicleCategories]);
    setChecklist([...vehicleCategories]);
  }, [setFilters, isMobile, setChecklist, setCategories, setFinanceType]);

  // Get vehicle data
  useEffect(() => {
    ( async () => {
      try {
        await getVehicles();
      } catch(e) {
        setError(true);
      }

      fireTag(
        'aa-component-view',
        { ...getGlobalBlock(),
          ...getPageBlock(contentSection)
        }
      );
      setIsPageLoaded(true);
    })();
  }, [setCategories, getVehicles, contentSection]);

  // Watch vehiclesList and transform data
  useEffect(() => {

    const { inBudgetOffers, almostInBudgetOffers } = getBudgetArrays(vehiclesList, seriesData );

    inBudgetOffers.sort((a,b) => a.payment - b.payment);
    almostInBudgetOffers.sort((a,b) => a.payment - b.payment);

    setInBudgetOffers(inBudgetOffers);
    setAlmostInBudgetOffers(almostInBudgetOffers);
  }, [vehiclesList, setInBudgetOffers, setAlmostInBudgetOffers, seriesData]);

  // Send postMessage height for these height changing variables
  useEffect(() => {
    window.setTimeout(() => {
      sendHeight();
    }, 100);
  }, [vehiclesList, showOffersDesktop, sendHeight]);

  const taggingBlocks = () => ({
    ...getGlobalBlock(),
    ...getPageBlock(contentSection),
    ...getOfferListBlock({layout: 'grid', includeAlmost: true}),
  });

  const handleViewOffers = (text: string) => {
    fireTag('aa-link',
    {
      ...taggingBlocks(),
      ...getLinkBlock({
        text,
        typeTitle: text,
        trigger: 'aa-link',
        buttonAction: 'Show Offers',
        type: 'Button'
      }),
      
    })

    setShowOffersDesktop(true);
  };

  const handleOnSliderChange = (minVal: number , maxVal: number) => {
    fireTag('aa-link',
    {
      ...taggingBlocks(),
      ...getLinkBlock({
        action: 'filter price',
        buttonAction: 'filter price',
        text: `${minVal} - ${maxVal}`,
        trigger: 'aa-link',
        typeClick: 'price-slider',
        typeTitle: 'Price',
      }),
    })

    setBudgetValueMin(minVal)
    setBudgetValueMax(maxVal)

    handleVehicleCountChange(!showOffersDesktop);
  };

  const handleVehicleCountChange = async (dontFireTag = false) => {
    const count = vehiclesList.length;
    const newVehiclesList = await getVehicles();
    const newCount = newVehiclesList.length;
    if (!dontFireTag && count !== newCount) {
      fireTag(
        'aa-pageload',
        { ...taggingBlocks() }
      );
    }
  } 

  const handleMinInputChange = (value: string) => {
    if (betweenEq(parseInt(value), min, currentBudgetValueMax)) {
      setBudgetValueMin(parseInt(value));
      handleVehicleCountChange(!showOffersDesktop);
    }
  }

  const handleMaxInputChange = (value: string) => {
    if (betweenEq(parseInt(value), currentBudgetValueMin, max)) {
      setBudgetValueMax(parseInt(value))
      handleVehicleCountChange(!showOffersDesktop);
    }
  }

  return (
    error ? <ErrorPanel /> :
    <FadeIn>
      <DesktopWrapper viewOffersOpen={showOffersDesktop}>
        <Header />
        <section
          className={styles.contentSection}
        >
          <div className={styles.topContainer}>
            {/* Sliders */}
            <Slider
              min={currentBudgetValueMin}
              max={currentBudgetValueMax}
              onInputMinChange={value => {
                setAreaControl('minPerMonth');
                clearTimeout(_timeout);
                _timeout = setTimeout(() => {
                  handleMinInputChange(value);
                }, 500) 
              }}
              onInputMaxChange={value => {
                setAreaControl('maxPerMonth');
                clearTimeout(_timeout);
                _timeout = setTimeout(() => {
                  handleMaxInputChange(value);
                }, 500) 
              }}
              onSliderChange={handleOnSliderChange}
            />
          </div>

          <div className={styles.bottomContainer}>
            {/* Additional Options */}
            <AdditionalOptions
              taggingBlocks={taggingBlocks()}
              checklist={checklist}
              onCategoryChecked={() => {
                setCategories([...checklist]);
                handleVehicleCountChange(!showOffersDesktop);
              }}
              onFinanceTypeChecked={(finance, lease) => {
                setFinanceType(finance, lease);
                handleVehicleCountChange(!showOffersDesktop);
              }}
            />
            { !isPageLoaded && <div className={styles.loader}><FadeLoader /></div> }
          </div>
          { isPageLoaded && !showOffersDesktop && 
            <div className={styles.viewOfferCtaContainer}>
              <Button
                id={areaControl}
                aria-live="polite"
                className={styles.viewOffersCta}
                variant='redBackground'
                onClick={() => handleViewOffers(`${t('budgetInterstitial.view')} ${inBudgetOffers.length} ${t('budgetInterstitial.results')}`) } 
              >
                {t('budgetInterstitial.view')} {inBudgetOffers.length + almostInBudgetOffers.length} {t('budgetInterstitial.results')}
              </Button>
            </div>
          }
        </section>
      </DesktopWrapper>

      { showOffersDesktop && <VehicleResults inBudgetOffers={inBudgetOffers} almostInBudgetOffers={almostInBudgetOffers} /> }

    </FadeIn>
  );
});

export default BudgetDesktop;
