import { RefactoredTrimsResponse, VapiTrimsResponse } from 'types/vapiMs.models';

export const refactorTrims = (data: VapiTrimsResponse) => {
  const refactoredTrims: RefactoredTrimsResponse = { years_list: [] };

  data.years.forEach(year => {
    refactoredTrims.years_list.push({
      year: year.year,
      series_list: year.series.map(vehicle => {
        return { series: vehicle.series, trims: vehicle.trims };
      }),
    });
  });

  return refactoredTrims;
};
