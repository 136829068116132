import React, { useState } from 'react';
import cx from 'classnames';
import styles from '../ContactDealer.module.scss';
import { Dealer } from '../../../types/dealer.models';
import { useTranslation } from 'react-i18next';

interface DealerListProps {
  dealers: Dealer[]
  selectedDealer?: Dealer,
  onDealerSelect: (dealer: Dealer) => void,
  limit: number,
  zip: string,
  onZipChange: (value: string) => void,
}

const DealerList = ({
  dealers,
  selectedDealer,
  onDealerSelect,
  limit,
  zip,
  onZipChange,
}: DealerListProps) => {

  const { t } = useTranslation();
  const [ editZipCode, setEditZipCode ] = useState(false);
  const [ currentZip, setCurrentZip ] = useState(zip);

  const zipRef = React.useRef<HTMLInputElement>(null);

  const handleZipChange = (value: string) => {
    setCurrentZip(value);

    if (value.length === 5) {
      onZipChange(value);
      setEditZipCode(false);
    }
  };
  
  const handleEditZipCodeOpen = () => {
    setEditZipCode(true);
    setTimeout(() => {
      if (zipRef.current) {
        zipRef.current.focus();
        zipRef.current.select();
      }
    })
  };

  return (
    <>
      {/* Header */}
      <label htmlFor='dealer' className={styles.label}>
        {t('raq.dealer')}
        <span className={styles.editZipWrapper}>
          {editZipCode ? (
            <input
              maxLength={5}
              name='zipcode'
              defaultValue={currentZip}
              className={cx({
                [styles.zipcode]: true,
                [styles.open]: editZipCode
              })}
              placeholder='Zip Code'
              ref={zipRef}
              onChange={e => handleZipChange(e.currentTarget.value)}
            />
          ) : (
            <span className={styles.zipcode}>{currentZip}</span>
          )}
          <button onClick={handleEditZipCodeOpen} className={styles.edit}>
            &#x270e;
          </button>
        </span>
      </label>
      {/* List */}
      <div className={styles.dealerList}>
        { dealers
          .slice(0, limit)
          .map((dealer: Dealer, index) => {
            return (
              <div
                key={index}
                className={styles.dealerDetailsContainer}
              >
                <input
                  type='radio'
                  className={cx(styles.radio, styles.option)}
                  defaultChecked={dealer === selectedDealer}
                  name='dealers'
                  onChange={() => onDealerSelect(dealer) }
                />
                <div className={styles.dealerDetails}>
                  <span className={styles.dealerName}>
                    {dealer.name}
                  </span>
                  <span className={styles.distance}>
                    ({dealer.distance} {t('raq.miles')})
                  </span>
                  <span className={styles.address}>
                    {dealer.contact.address}
                  </span>
                </div>
              </div>
            );
        })}
      </div>
    </>
  );
};

export default DealerList;
