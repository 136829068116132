import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { buildDisclaimerLink } from '../../utils/peLinkUtil';
import Button from '../../../src/components/Button';
import Container from '../../../src/components/Container';
import ModalClose from '../../../src/components/Modal/ModalClose';
import Title from '../../../src/components/Title';
import useMobileDetect from '../../../src/hooks/useMobileDetect';
import { AppStoreContext } from '../../../src/stores/AppStore';
import { Dealer } from '../../../src/types/dealer.models';
import { VehiclesResponseItem } from '../../../src/types/vehiclesResponse.models';
import { fireTag } from '../../../src/utils/Analytics';
import { PostMessageStoreContext } from '../../stores/PostMessageStore';
import { getDealerBlockFromResponse } from '../../utils/Analytics/blocks/getDealerBlock';
import getGlobalBlock from '../../utils/Analytics/blocks/getGlobalBlock';
import getLinkBlock from '../../utils/Analytics/blocks/getLinkBlock';
import { getOfferBlockFromResponse } from '../../utils/Analytics/blocks/getOfferBlock';
import getPageBlock from '../../utils/Analytics/blocks/getPageBlock';
import { getVehicleBlockFromResponse } from '../../utils/Analytics/blocks/getVehicleBlock';
import DealerList from './DealerList';
import InputForm from './InputForm';
import Success from './Success';
import styles from './ContactDealer.module.scss';

interface ContactDealerProps {
  vehicle: VehiclesResponseItem;
  onClose: () => void;
}

const ContactDealer = observer(({ vehicle, onClose }: ContactDealerProps) => {
  const {
    AppStore: { campaignCode, getDealers, language, submitRAQ, zip },
  } = React.useContext(AppStoreContext);

  const {
    postMessageStore: { scrollTop },
  } = React.useContext(PostMessageStoreContext);

  const { t } = useTranslation();
  const tRequestAQuote = t('common.requestAQuote');

  const { isMobile } = useMobileDetect();

  const [limit, setLimit] = useState(2);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dealers, setDealers] = useState<Dealer[]>([]);
  const [selectedDealer, setSelectedDealer] = useState<Dealer>();
  const [firstInteraction, setFirstInteraction] = useState(false);
  const [error, setError] = useState(false);

  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' }); // initialise the hook

  const contentSection = 'wfmb_raq_overlay';
  // trim html tags from series name.
  const seriesName = vehicle.seriesName.replace(/(<([^<]+)>)/gi, '');
  const grade = vehicle.grade.replace(/(<([^<]+)>)/gi, '');
  const selectedVehicle = `${seriesName} ${grade}`;
  // trim duplicates from selected vehicle.
  const vehicleTitle = selectedVehicle.replace(/(\b\S.+\b)(?=.*\1)/g, '').trim();

  useEffect(() => {
    (async () => {
      const dealers = await getDealers(zip);
      setSelectedDealer(dealers[0]);
      setDealers(dealers);
      scrollTop();
      fireTag('ready', {
        metrics: 'km-raq-shown,km-dealer-search',
        ...getPageBlock(contentSection),
        ...getVehicleBlockFromResponse(vehicle),
        ...getDealerBlockFromResponse(dealers[0]),
        ...getOfferBlockFromResponse(vehicle),
      });
    })();
  }, [getDealers, vehicle, zip, scrollTop]);

  const taggingBlocks = {
    ...getGlobalBlock(),
    ...getPageBlock(contentSection),
    ...getVehicleBlockFromResponse(vehicle),
    ...(selectedDealer && getDealerBlockFromResponse(selectedDealer)),
    ...getOfferBlockFromResponse(vehicle),
  };

  const onSubmit = async (data: Record<string, string>) => {
    try {
      setError(false);
      const { firstname, lastname, preference, email, phone, comments } = data;
      const purchaseType = vehicle.purchaseType;
      const params = {
        vehicle: vehicleTitle,
        dealer: selectedDealer as Dealer,
        firstname,
        lastname,
        preference,
        email,
        phone,
        comments,
        isMobile,
        campaignCode,
        purchaseType,
      };
      fireTag('aa-link', {
        ...taggingBlocks,
        ...getLinkBlock({
          buttonAction: 'raq find dealer form submit',
          text: 'submit',
          trigger: 'aa-link',
          typeTitle: 'your contact information',
          type: 'free text',
        }),
      });
      await submitRAQ(params);

      setIsSubmitted(true);
    } catch {
      setError(true);
    }
  };

  const loadMore = () => {
    if (limit <= dealers.length) {
      setLimit(limit + 5);
    } else {
      setLimit(2);
    }
  };

  const handleInteraction = (text: string) => {
    if (!firstInteraction) {
      fieldInteractionTag(text);
      setFirstInteraction(true);
    }
  };

  const fieldInteractionTag = (text: string) => {
    fireTag('km-link', {
      metrics: 'km-raq-start',
      ...getPageBlock(contentSection),
      ...getVehicleBlockFromResponse(vehicle),
      ...getDealerBlockFromResponse(selectedDealer as Dealer),
      ...getOfferBlockFromResponse(vehicle),
      ...getLinkBlock({
        buttonAction: 'raq find dealer form start',
        text: text,
        trigger: 'km-link',
        typeTitle: 'your contact information',
        type: 'free text',
      }),
    });
  };

  const handleDealerZipChange = async (value: string) => {
    const dealers = await getDealers(value);
    setSelectedDealer(dealers[0]);
    setDealers(dealers);
  };

  if (isSubmitted) {
    return <Success selectedTrim={vehicle} dealer={selectedDealer as Dealer} onClose={onClose} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.raqForm}>
        <ModalClose close={() => onClose()} />
        <Container>
          <Title>
            <span className={styles.financeTitle}>{t('raq.financeTitle')}</span>
            {tRequestAQuote}
          </Title>
          <label htmlFor='vehicle' className={styles.label}>
            {t('raq.vehicle')}
          </label>

          {/* Selected Vehicle */}
          <div className={styles.input}>{vehicleTitle}</div>

          <InputForm onInteraction={handleInteraction} register={register} />

          {/* Dealers */}
          <div className={styles.dealerWrapper}>
            <DealerList dealers={dealers} selectedDealer={selectedDealer} onDealerSelect={setSelectedDealer} limit={limit} zip={zip} onZipChange={handleDealerZipChange} />
          </div>

          {/* View More Dealers */}
          <div className={styles.viewMore}>
            <button onClick={loadMore}>
              {t('raq.viewMore')}
              {limit < dealers.length && <FontAwesomeIcon className={styles.caret} icon={faAngleDown} />}
              {limit >= dealers.length && <FontAwesomeIcon className={styles.caret} icon={faAngleUp} />}
            </button>
          </div>

          <div className={styles.disclaimerWrapper}>
            {t('raq.disclaimer.text')}{' '}
            <button className={styles.disclaimerButton} onClick={() => window.open(buildDisclaimerLink(language))}>
              {t('raq.disclaimer.clickHere')}
            </button>
          </div>

          {/* Submit */}
          <div className={styles.quoteCta}>
            <Button type='submit' variant='redBackground' disabled={!formState.isValid}>
              {t('raq.quoteCta')}
            </Button>
          </div>
          {error && <p className={styles.error}>{t('raq.error')}</p>}
        </Container>
      </div>
    </form>
  );
});

export default ContactDealer;
