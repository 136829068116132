import cx from "classnames";
import { observer } from "mobx-react";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import ContactDealer from "../../../../../src/components/ContactDealer";
import Modal from "../../../../../src/components/Modal";
import NumberFormat from "../../../../../src/components/NumberFormat";
import { AppStoreContext } from "../../../../../src/stores/AppStore";
import { VehiclesResponseItem } from "../../../../../src/types/vehiclesResponse.models";
import { fireTag } from "../../../../../src/utils/Analytics";
import { getPeLink, showPeLink } from "../../../../../src/utils/peLinkUtil";
import AALinkAnchor from "../../../../utils/Analytics/AALinkAnchor";
import AALinkRouterAnchor from "../../../../utils/Analytics/AALinkRouterAnchor";
import getGlobalBlock from "../../../../utils/Analytics/blocks/getGlobalBlock";
import getLinkBlock from "../../../../utils/Analytics/blocks/getLinkBlock";
import getListItem from "../../../../utils/Analytics/blocks/getListItem";
import { getOfferBlockFromResponse } from "../../../../utils/Analytics/blocks/getOfferBlock";
import getOfferListBlock from "../../../../utils/Analytics/blocks/getOfferListBlock";
import getPageBlock from "../../../../utils/Analytics/blocks/getPageBlock";
import CarJellyImage from "./components/CarJellyImage";
import Header from "./components/Header";
import styles from "./VehicleCard.module.scss";

interface VehicleCardProps {
  offer: VehiclesResponseItem;
  almostInBudget?: boolean;
  index: number;
}

const VehicleCard = ({
  offer,
  almostInBudget = false,
  index,
}: VehicleCardProps) => {
  const { t } = useTranslation();
  const tRequestAQuote = t("common.requestAQuote");
  const tOfferDetails = t("vehicleCard.offerDetails");

  const {
    AppStore: { zip, tdaCode, setSelectedTrim, language, filters, domain },
  } = React.useContext(AppStoreContext);

  const [openContactDealer, setOpenContactDealer] = React.useState(false);
  const cardLink = `/vehicleDetails/${offer.series}/${offer.model}/${offer.purchaseType}?zip=${zip}/?lang=${language}/?filters=${filters}`;

  const taggingBlocks = {
    ...getGlobalBlock(),
    ...getPageBlock("wfmb_offer_results"),
    ...getOfferBlockFromResponse(offer),
    ...getOfferListBlock({ layout: "grid" }),
  };

  const handleViewDetails = (text: string) => {
    setSelectedTrim(offer);
  };

  const handleOpenRAQ = (text: string) => {
    fireTag("aa-link", {
      ...taggingBlocks,
      ...getLinkBlock({
        text,
        typeTitle: text,
        trigger: "aa-link",
        buttonAction: "Open Modal",
        type: "Button",
      }),
    });
    setSelectedTrim(offer);
    setOpenContactDealer(true);
  };

  return (
    <section
      className={cx({
        [styles.vehicleCard]: true,
        [styles.almostInBudget]: almostInBudget,
      })}
    >
      <div className={styles.vehicleCardContent}>
        <Header offer={offer} taggingBlocks={taggingBlocks} />
        {/* Car Jelly */}
        <main className={styles.body}>
          {showPeLink(almostInBudget, offer) ? (
            <AALinkAnchor
              href={getPeLink(tdaCode, zip, offer, language, domain)}
              getBlocks={() => ({
                ...taggingBlocks,
                ...getListItem(index),
              })}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
              linkText={offer.series}
            >
              <CarJellyImage offer={offer} />
            </AALinkAnchor>
          ) : (
            <AALinkRouterAnchor
              getBlocks={() => ({
                ...taggingBlocks,
                ...getListItem(index),
              })}
              to={cardLink}
              linkText={offer.series}
              onClick={() => handleViewDetails(tOfferDetails)}
            >
              <CarJellyImage offer={offer} />
            </AALinkRouterAnchor>
          )}

          {/* Offer Type */}
          <div className={styles.offerTypeContainer}>
            <div
              className={cx({
                [styles.financeTypeBuy]: offer.purchaseType === "buy",
                [styles.financeTypeLease]: offer.purchaseType === "lease",
              })}
            >
              {offer.purchaseType === "buy" ? "Finance" : "Lease"}
            </div>
            <div className={styles.series}>
              {ReactHtmlParser(`${offer.year} ${offer.seriesName}`)}
            </div>
          </div>

          {/* Offer Details */}
          <div className={styles.offerDetails}>
            <div className={styles.detail}>
              <span className={styles.value}>{offer.term}</span>{" "}
              {t("vehicleDetails.mos")}
            </div>
            <div className={styles.separator}></div>
            {offer.purchaseType === "buy" && (
              <>
                <div className={styles.detail}>
                  <span className={styles.value}>{offer.rate}%</span> APR
                </div>
                <div className={styles.separator}></div>
                <div className={styles.detail}>
                  <span className={styles.value}>
                    $<NumberFormat value={offer.downPayment} />
                  </span>
                  <span> {t("vehicleDetails.downPayment")}</span>
                </div>
              </>
            )}
            {offer.purchaseType === "lease" && (
              <div className={styles.detail}>
                <span className={styles.value}>
                  $<NumberFormat value={offer.dueAtSigning} />
                </span>{" "}
                {t("common.dueAtSigning")}
              </div>
            )}
          </div>
        </main>
        <footer className={styles.footer}>
          {showPeLink(almostInBudget, offer) ? (
            <AALinkAnchor
              getBlocks={() => ({
                ...taggingBlocks,
                ...getListItem(index),
              })}
              href={getPeLink(tdaCode, zip, offer, language, domain)}
              className={cx(styles.link, styles.paymentDetailsButton)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("vehicleCard.viewPayment")}
            </AALinkAnchor>
          ) : (
            <AALinkRouterAnchor
              getBlocks={() => ({
                ...taggingBlocks,
                ...getListItem(index),
              })}
              to={cardLink}
              className={cx(styles.link, styles.paymentDetailsButton)}
              onClick={() => handleViewDetails(tOfferDetails)}
            >
              {tOfferDetails}
            </AALinkRouterAnchor>
          )}
          <button
            className={cx(styles.link, styles.RaqButton)}
            onClick={() => handleOpenRAQ(tRequestAQuote)}
          >
            {tRequestAQuote}
          </button>
        </footer>
      </div>
      <Modal
        open={openContactDealer}
        onClose={() => setOpenContactDealer(false)}
      >
        <ContactDealer
          vehicle={offer}
          onClose={() => setOpenContactDealer(false)}
        />
      </Modal>
    </section>
  );
};

export default observer(VehicleCard);
